import Store from "@/store";
import httpService from "@/services/http/HttpService";
import {alertError} from "@/helper/alertHelper";
import {getBookByUserAndId} from "@/services/api/bookService";

const changeStatus = (data, bookId) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/books/${bookId}/manager/update-status`

     httpService.patch(url, {read_status: data.managerBook.readStatus})
         .then(result => {
             data.showFlashAlert = true;
             setInterval(() => {
                 data.showFlashAlert = false
             }, 6000)
             getBookByUserAndId(data, bookId)
         }).catch(error => {
             alertError("Atenção", "Falha ao atualizar status do livro")
         })
}

const saveUpdateReadBook = (data, bookId) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/books/${bookId}/manager/read-history/${data.currentReadUpdateId}/updates`

    httpService.post(url, data.managerBook.updateRead)
        .then(result => {
            data.openPageForm = false
            data.managerBook.updateRead.unit = "page"
            data.managerBook.updateRead.amount = ""
            getBookByUserAndId(data, bookId)
        }).catch(error => {
        alertError("Atenção", "Falha ao inserir atualização de leitura")
    })
}

const deleteUpdateReadBook = (data, bookId, readStatusId) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/books/${bookId}/manager/read-history/${data.currentReadUpdateId}/updates/${readStatusId}`

    httpService.delete(url)
        .then(result => {
            getBookByUserAndId(data, bookId)
        }).catch(error => {
        alertError("Atenção", "Falha ao deletar atualização de leitura")
    })
}

export {
    changeStatus,
    saveUpdateReadBook,
    deleteUpdateReadBook
}