import Store from "@/store";
import httpService from "@/services/http/HttpService";
import {alertError} from "@/helper/alertHelper";

const getReadResume = async () => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/statistics/read-resume`

    try {
        let result = await httpService.get(url)
        return result.data
    }catch(e) {
        alertError("Atenção", "Falha ao obter Resumo de leitura")
    }
}

export {
    getReadResume
}