import httpService from "@/services/http/HttpService";
import Store from "@/store";
import {alertConfirm, alertError, alertSuccess} from "@/helper/alertHelper";
import HttpService from "@/services/http/HttpService";
import {getNationality} from "@/utils/NationalityConstant";

const listAllBooks = (data) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/books?page=${data.pagination.current_page}&limit=${data.pagination.limit}`
    url = formatFilterUrl(url, data)
    data.showLoading = true
    httpService.get(url).then(result =>{
         data.pagination.current_page = result.data.current
         data.pagination.totalPages = result.data.total_pages
         data.pagination.totalRows = result.data.total_rows
         data.books = result.data.items
         data.showLoading = false
    }).catch(error => {
         data.showLoading = false
         alertError("Atenção", "Falha ao obter livros, tente novamente ou contate o administrador")
    })
}

const formatFilterUrl = (url, data) => {
    let formattedUrl = url;
    if(data.selectedFilters.category !== "") {
       formattedUrl = formattedUrl.concat(`&category=${data.selectedFilters.category.toLowerCase()}`)
    }

    if(data.selectedFilters.version !== "") {
        formattedUrl = formattedUrl.concat(`&version=${data.selectedFilters.version.toLowerCase()}`)
    }

    if(data.selectedFilters.readStatus !== "") {
        formattedUrl = formattedUrl.concat(`&status=${data.selectedFilters.readStatus}`)
    }

    if(data.selectedFilters.nationality !== "") {
        formattedUrl = formattedUrl.concat(`&nationality=${data.selectedFilters.nationality.toLowerCase()}`)
    }

    if(data.selectedFilters.description !== "") {
        formattedUrl = formattedUrl.concat(`&description=${data.selectedFilters.description.toLowerCase()}`)
    }

    return formattedUrl;
}



const saveBook = async (data, book, router) => {
     try{
         data.showLoading = true;
         let userId = Store.getters.userData.userId
         let url = `/users/${userId}/books`
         let result = await HttpService.post(url, book)
         data.showLoading = false;
         alertConfirm("Sucesso!", "Livro cadastrado com sucesso, deseja cadastra outro livro ?", () => {
                 data.book.title = ""
                 data.book.author = ""
                 data.book.pages = ""
                 data.book.publish =""
                 data.book.publishDate =""
                 data.book.isbnCode = ""
                 data.book.readYear = ""
                 data.book.amount = ""
                 data.book.categories = []
                 data.book.versions = []
                 data.book.nationality = ""
                 data.imageKey +=1
         }, () => {
             router.push({name:"my-books"})
         }, 'success')
         return result.data
     }catch (e) {
         data.showLoading = true;
         alertError("Atenção", "Falha ao criar livro")
     }
}

const updatedBook = async (data, book, id) => {
    data.showLoading = true
    try{
        let userId = Store.getters.userData.userId
        let url = `/users/${userId}/books/${id}`
        let result = await HttpService.put(url, book)
        data.showLoading = false
        alertSuccess("Sucesso!", "Livro atualizado com sucesso")
        data.showModal = true
        data.componentKey+=1
        return result.data
    }catch (e) {
        data.showLoading = false
        alertError("Atenção", "Falha ao atualizar livro")
    }
}


const getBookByUserAndId = (data, id) => {
    data.showLoading = true
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/books/${id}`
    httpService.get(url).then(result => {
         data.showLoading = false
         data.book.cover = result.data.cover
         data.book.title = result.data.title
         data.book.author = result.data.author
         data.book.pages = result.data.pages
         data.book.publish = result.data.publisher
         data.book.publishDate = result.data.publish_date
         data.book.isbnCode = result.data.isbn_code
         data.book.readYear = result.data.read_year
         data.book.amount = result.data.amount / 100
         data.book.categories = result.data.categories
         data.book.versions = result.data.versions
         data.book.start_read_history = result.data.start_read_history
         data.book.readHistories = result.data.read_histories
         data.book.favorite = result.data.favorite
         data.book.stars = result.data.stars
         data.book.startRead = result.data.start_read
         data.book.endRead = result.data.end_read
         let filterReadHistory = result.data.read_histories.filter( item => item.close === 0)
         if(filterReadHistory.length > 0) {
             data.currentReadUpdateId = filterReadHistory[0].id
             data.currentReadUpdates = filterReadHistory[0].updates
         }else{
             data.currentReadUpdateId = ""
             data.currentReadUpdates = []
         }
         data.book.nationality = {description: getNationality(result.data.nationality), id:result.data.nationality}
         data.managerBook.readStatus =result.data.read_status
    }).catch(error => {
        alertError("Atenção","Falha ao obter os dados")
        data.showLoading = false
    })
}

const getBookById = async (data, id) => {
    data.showLoading = true
    try {
        let userId = Store.getters.userData.userId
        let url = `/users/${userId}/books/${id}`
        let result = await HttpService.get(url)
        data.showLoading = false
        result.data
    }catch (e) {
        alertError("Atenção", "Falha ao obter dados do livro")
        data.showLoading = false
    }
}

const deleteBook = async (data, bookId, router) => {
     data.showLoading = true
     try{
         let userId = Store.getters.userData.userId
         let url = `/users/${userId}/books/${bookId}`
         let result = await HttpService.delete(url)
         data.showLoading = false
         alertSuccess("Sucesso!", "Livro Deletado com sucesso!!")
         router.push({name:"my-books"})
     }catch (e) {
         alertError("Atenção", "Falha ao deletar livro")
     }
}

export {
    listAllBooks,
    saveBook,
    deleteBook,
    getBookById,
    getBookByUserAndId,
    updatedBook
}