let statusList = [
    {
        description: "para_ler",
        formatted: "Para ler"
    },
    {
        description: "lendo",
        formatted: "Lendo"
    },
    {
        description: "para_ler",
        formatted: "Para ler"
    },
    {
        description: "ja_li",
        formatted: "Já li"
    },

    {
        description: "pausei",
        formatted: "Pausei"
    },
    {
        description: "relendo",
        formatted: "Relendo"
    },
    {
        description: "abandonei",
        formatted: "Abandonei"
    },

]

const formatStatus = (status) => {
     let filter = statusList.filter( value => value.description === status)
     if(filter.length > 0) {
         return filter[0].formatted
     }
     return ""
}

export {
    formatStatus
}