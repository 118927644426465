<template>
    <div class="container-fluid page-container">
         <div class="sidebar">

           <book-filter @filter-item="filterBooks" @clear-filters="clearFilters" :data="data.filters"></book-filter>

           <div class="card sidebar-card">
             <div class="card-body">
               <h3 class="filter-title"><font-awesome-icon icon="fa-solid fa-book"></font-awesome-icon> Minhas Leituras</h3>
               <div class="my-reads">
                 <no-content message="Ainda não há dados" v-if="!data.lastReadBooks.length"></no-content>
                 <div v-else class="my-reads-item" v-for="item in data.lastReadBooks">
                     <img :src="item.cover">
                     <div class="my-read-item-content">
                       <h3>{{item.title}}</h3>
                       <div class="progress">
                         <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" :style="`width:${item.percentage}%`">{{item.percentage}}%</div>
                       </div>
                       <span>Total Paginas: {{item.pages}} | Total lidas: {{item.amount_read}}</span>
                     </div>
                 </div>
               </div>
             </div>
           </div>

           <div class="card statistic-books-mobile sidebar-card">
             <div class="card-body">
               <h3 style="margin-bottom: 20px">Resumo de leitura</h3>
               <div class="statistic-books-content">
                 <div class="statistic-item" v-for="item in data.statistics.readResume">
                   <h4>{{formatStatus(item.status)}}</h4>
                   <span>{{item.count}}</span>
                 </div>
               </div>
             </div>
           </div>

         </div>
      <div class="libray-content">

         <div class="card statistic-books">
            <div class="card-body">
              <h3 style="margin-bottom: 20px">Resumo de leitura</h3>
              <div class="statistic-books-content">
                  <div class="statistic-item" v-for="item in data.statistics.readResume">
                      <h4>{{formatStatus(item.status)}}</h4>
                      <span>{{item.count}}</span>
                  </div>
              </div>
            </div>
         </div>
        <div class="card" style="margin-bottom: 20px">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="input-group">
                    <input type="text" placeholder="Titulo ou Autor do livro" v-model="data.selectedFilters.description" class="form-control">
                  <button type="button" @click="filterDescription(true)" class="btn btn-primary btn-forms-primary" v-if="data.activeDescriptionSearch"><font-awesome-icon icon="fa-solid fa-xmark"></font-awesome-icon> </button>
                    <button type="button" @click="filterDescription(false)" class="btn btn-primary btn-forms-primary"><font-awesome-icon icon="fa-solid fa-search"></font-awesome-icon> </button>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div class="card">
            <div class="card-body">
                <div class="library-list">
                      <book v-for="item in data.books" @delete-book="deleteBookConfirm" @manager-book="openManagerBook" :data="item"></book>
                </div>

                <no-content message="Não há livros cadastrados" v-if="!data.books.length"></no-content>

              <nav aria-label="Page navigation example" v-if="data.books.length">
                <ul class="pagination">
                  <li class="page-item" ><a @click.prevent="navigatePages('prev')" class="page-link" href="#">Anterior</a></li>
                  <li v-for="page in data.pagination.pages" class="page-item">
                    <span class="page-link" v-if="page === '...'">...</span>
                    <a v-else @click.prevent="changePage(page)" class="page-link" :class="{active: data.pagination.current_page === page}" href="#">{{page}}</a>
                  </li>
                  <li class="page-item"><a @click.prevent="navigatePages('next')" class="page-link" href="#">Proximo</a></li>
                </ul>
              </nav>
            </div>
          </div>
      </div>
      <a class="btn btn-primary float-button" @click.prevent="openCloseModal('new')" href="">
        <font-awesome-icon style="font-size: 2em;padding-top: 12px" icon="fa-solid fa-book"></font-awesome-icon>
      </a>

    </div>
    <modal v-if="data.showModal"
           :show-action-buttons="false"
           :show-close-button="true"
           @close-modal="openCloseModal()"
           :title="data.modal.title" icon="fa-solid fa-book">
        <book-fragment v-if="data.bookOperationMode === 'new'" @cancel-form="data.showModal = false" @book-form="saveBookModal"></book-fragment>
        <book-manager-fragment :key="data.componentKey" :book-id="data.bookId" @delete-book="deleteBookConfirm" @cancel-form="data.showModal = false" v-if="data.bookOperationMode === 'manager'" @book-form-update="updateBookModal"></book-manager-fragment>
    </modal>
  <loading v-if="data.showLoading" message="Processando aguarde.."></loading>
</template>

<script>
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
  import Modal from "@/components/modal/modal.vue";
  import {computed, onMounted, reactive, watch} from "vue";
  import BookFilter from "@/components/view/filter/book-filter.vue";
  import {getFilters} from "@/services/api/filterService";
  import {deleteBook, getBookById, listAllBooks, saveBook, updatedBook} from "@/services/api/bookService";
  import Book from "@/components/view/book/book.vue";
  import {generatePagesArray} from "@/helper/Pagination";
  import BookFragment from "@/views/mybooks/fragment/book-fragment.vue";
  import Loading from "@/components/loading/loading.vue";
  import {alertConfirm, alertError} from "@/helper/alertHelper";
  import NoContent from "@/components/nocontent/NoContent.vue";
  import {getReadResume} from "@/services/api/statisticsService";
  import {formatStatus} from "../../utils/readStatusFormatUtils";
  import filters from "@/utils/FilterConstant";
  import BookManagerFragment from "@/views/mybooks/fragment/book-manager-fragment.vue";
  import {getLastReads} from "@/services/api/bookReadService";
  import {useRouter} from "vue-router";

  export default {
    methods: {formatStatus},
    components: {BookManagerFragment, NoContent, Loading,BookFragment , Book, BookFilter, Modal, FontAwesomeIcon},
      setup() {
          let router = useRouter()
          let data = reactive({
               showModal: false,
               bookOperationMode: "new",
               componentKey: 0,
               lastReadBooks: [],
               modal: {
                  title: "Novo Livro"
               },
               selectedFilters: {
                 nationality:"",
                 readStatus: "",
                 category: "",
                 version: "",
                 description:""
               },
               activeDescriptionSearch: false,
               filters: [],
               books:[],
               book: {},
              pagination: {
                pages: [],
                limit: 10,
                current_page:1,
                totalPages: 0,
                totalRows: 0
              },
              showLoading: false,
              statistics: {
                 readResume: []
              },
              bookId:null
          })


        const filterDescription = (clearFilter) => {
             if(clearFilter) {
                data.activeDescriptionSearch = false;
                data.selectedFilters.description = "";
                listAllBooks(data)
                return
             }

             if(data.selectedFilters.description === "") {
                 alertError("Atenção", "Preencha o campo com o Titulo do livro ou Autor.")
                 return
             }

             data.activeDescriptionSearch = true
             listAllBooks(data)

        }

        const filterBooks = (itemFilter) => {
             if(itemFilter.filterName === filters.Nacionalidade) {
                data.selectedFilters.nationality = itemFilter.itemDescription
             }

              if(itemFilter.filterName === filters.Genero) {
                data.selectedFilters.category = itemFilter.itemSlugName
              }

              if(itemFilter.filterName === filters.Versao) {
                data.selectedFilters.version = itemFilter.itemDescription
              }

              if(itemFilter.filterName === filters.Status) {
                data.selectedFilters.readStatus = itemFilter.itemDescription
              }
              listAllBooks(data)
        }
        const openCloseModal = (mode, id) => {
            if(mode === 'new') {
              router.push({name: "create-book-form"})
            }else{
               router.push({name:"book-manager-form", params:{bookId: id}})
            }
        }

        const openManagerBook = (eventData) => {
            openCloseModal("manager",eventData.id)
        }


        const navigatePages = (direction) => {
          if(direction === 'prev') {
            if(data.pagination.current_page > 1) {
              data.pagination.current_page -=1
              listAllBooks(data)
              return;
            }
          }

          if(direction === 'next') {
            if(data.pagination.current_page >= data.pagination.totalPages) {
              return
            }
            data.pagination.current_page +=1
            listAllBooks(data)
          }
        }

        const changePage = (page) => {
            data.pagination.current_page = page;
            listAllBooks(data)
        }

        const saveBookModal = async (book) => {
            await saveBook(data,book)
        }

        const updateBookModal = async (bookEvent) => {
          await updatedBook(data,bookEvent.book, bookEvent.bookId)
        }

        const deleteBookConfirm = (bookData) => {
             alertConfirm("Confirmação", `Deseja deletar o livro ${bookData.title}`, () => {
                    deleteBook(data, bookData.id)
             })
        }

        const clearFilters = () => {
             data.selectedFilters.nationality = ""
             data.selectedFilters.category = ""
             data.selectedFilters.version = ""
             data.selectedFilters.readStatus = ""
             listAllBooks(data)
        }

        onMounted(async () => {
            getFilters(data)
            listAllBooks(data)
            getLastReads(data)
            data.statistics.readResume = await getReadResume()
        });

        watch(() => data.books , (books) => {
          if(data.pagination.current_page > 1 && books.length === 0) {
            data.pagination.current_page -= 1
            listAllBooks(data)
          }
          data.pagination.pages = generatePagesArray(data.pagination.current_page, data.pagination.totalRows, data.pagination.limit, 8)
        })

        return {
            data,
            openCloseModal,
            filterBooks,
            navigatePages,
            changePage,
            saveBookModal,
            deleteBookConfirm,
            clearFilters,
            openManagerBook,
            updateBookModal,
            filterDescription
        }
      }
  }
</script>

<style scoped>
    @import "mybookstyle.scss";
</style>