import {alertError} from "@/helper/alertHelper";
import httpService from "@/services/http/HttpService";

const listAll = async () => {
     try {
        let result = await httpService.get("/book-categories");
        return result.data;
     }catch (e) {
         alertError("Atenção", "Falha ao buscar categorias")
     }
}

const listAllSync = (data) => {
    httpService.get("/book-categories").then(result => {
        data.bookCategories = result.data
    }).catch(error => {
        alertError("Atenção", "Falha ao buscar categorias")
    })
}

export {
    listAll,
    listAllSync
}