<template>
    <div class="book-content">
         <div class="book" @click="openManagerBook">
          <font-awesome-icon v-if="$props.data.favorite" style="position: absolute; top: 10px;right: 10px;color: red ;z-index: 500" icon="fa-solid fa-heart"></font-awesome-icon>
          <img :src="$props.data.cover"/>
          <h4>{{$props.data.title}}</h4>
           <span style="font-size: 12px">{{$props.data.author}}</span>
          <div class="star-aval">
            <font-awesome-icon v-for="number in 5" :class="{'yellow-star': calculateStars(number,$props.data.stars)}" icon="fa-solid fa-star"></font-awesome-icon>
          </div>
         </div>
    </div>
</template>

<script>
   import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

   export default {
       name: "book",
       components: {FontAwesomeIcon},
       props:['data'],
       setup(props, ctx) {
         const calculateStars = (number, stars) => {
              let initialStar = 1
              if(stars === 0) {
                return false;
              }
              if(number >= initialStar && number <= stars) {
                  return true;
              }
         }

         const deleteBook = () => {
            ctx.emit("delete-book", props.data)
         }

         const openManagerBook = () => {
            ctx.emit("manager-book", props.data)
         }

         return {
           calculateStars,
           deleteBook,
           openManagerBook
         }
       }
   }
</script>

<style scoped>


.book-content {
  background: #f6f6f6;
  margin-right: 14px;
  margin-bottom: 20px;
}

.book{
  position: relative;
}

.book {
  cursor: pointer;
  width: 180px;
  height: 320px;
  padding: 10px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 20px;
}

.book img {
  width: 150px;
  height: 200px;
  margin-bottom: 10px;
  transition: transform .5s;

}

.book img:hover{
   transform: scale(1.05);
}

.book h4{
  font-size: 1em;
}

.yellow-star {
   color:#c2aa11
}
</style>
