<template>
  <div @click="openUploadField" id="thumb-cover" class="thumb-cover d-flex align-center justify-content-center">
       <h4 v-if="!data.imagePreview"> Clique para adicionar uma capa</h4>
       <img v-else :src="data.imagePreview" alt="thumb" class="img-thumb">
  </div>
  <input type="file" style="display: none" @change="previewImage" id="cover" accept="image/*">
</template>

<script>
import {reactive, watch} from "vue";
   export default {
      name: "upload-preview",
      props: ['imageCover'],
      setup(props, ctx) {
          let data = reactive({
            imagePreview: props.imageCover
          })

          const previewImage = (event) => {

            const file = event.target.files[0];

            if(file == null) {
              data.imagePreview = ""
              return
            }

            const reader = new FileReader();

            reader.onload = (e) => {
              data.imagePreview = e.target.result;
            };
            reader.readAsDataURL(file);
            ctx.emit("upload-image", file)
          }

          const openUploadField= () => {
             let cover = document.querySelector("#cover")
             cover.click();
          }

          watch(() => props.imageCover, (newValue, oldValue) => {
               data.imagePreview = newValue
          })

          return {
            data,
            previewImage,
            openUploadField
          }
      }
   }
</script>

<style scoped>
.thumb-cover{
  width: 210px;
  height: 250px;
  background: #e9e9e9;
  cursor: pointer;
}

.img-thumb {
  width: 210px;
  height: 250px;
}

.thumb-cover h4 {
    font-size: 14px;
}
</style>