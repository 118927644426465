<template>
    <div class="container-fluid">
      <nav class="web-menu">
        <div class="mobile-menu">
          <button class="mobile-btn" @click="openMobileMenu" type="button"><font-awesome-icon icon="fa-solid fa-bars"></font-awesome-icon></button>
           <div class="mobile-menu-content" :class="{'openMobileMenu': data.mobileMenuOpen}">
               <div class="mobile-menu-header d-flex justify-content-end">
                <a href="" class="close-mobile-menu-button" type="button" @click.prevent="openMobileMenu">X</a>
               </div>

               <div class="mobile-menu-body">
                    <ul class="d-flex flex-column">
                      <li><a href="" @click.prevent="changeMenuItem('dashboard')"><font-awesome-icon icon="fa-solid fa-dashboard"></font-awesome-icon> Dashboard</a></li>
                      <li><a href="" @click.prevent="changeMenuItem('my-books')"><font-awesome-icon icon="fa-solid fa-book"></font-awesome-icon> Meus Livros</a></li>
                      <li><a href="" @click.prevent="logoutApp"><font-awesome-icon icon="fa-solid fa-door-open"></font-awesome-icon> Sair</a> </li>
                    </ul>
               </div>

           </div>
        </div>
        <div class="title"><font-awesome-icon icon="fa-solid fa-book"></font-awesome-icon> Livraria da Steffani</div>
        <nav>
          <ul>
            <li><router-link to="/app/dashboard"><font-awesome-icon icon="fa-solid fa-dashboard"></font-awesome-icon> Dashboard</router-link></li>
            <li><router-link to="/app/my-books"><font-awesome-icon icon="fa-solid fa-book"></font-awesome-icon> Meus Livros</router-link></li>
          </ul>
        </nav>
        <button class="logout-btn" type="button" @click="logoutApp"><font-awesome-icon icon="fa-solid fa-door-open"></font-awesome-icon> Sair</button>
      </nav>
      <router-view></router-view>
    </div>
</template>

<script>
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import {alertConfirm} from "@/helper/alertHelper";
    import store from "@/store";
    import {useRouter} from "vue-router";
    import {reactive} from "vue";
    export default {
      components: {FontAwesomeIcon},
        setup() {

          let data = reactive({
               mobileMenuOpen: false
          })

          const router = useRouter();


          const openMobileMenu = () => {
              if(data.mobileMenuOpen) {
                data.mobileMenuOpen = false
                return;
              }
              data.mobileMenuOpen = true
          }

          const changeMenuItem = (nameRoute) => {
              data.mobileMenuOpen = false
              router.push({name:nameRoute})
          }

          const logoutApp = () => {
               alertConfirm("Confirmação", "Deseja deslogar do sistema ?", () => {
                    store.commit("clearState")
                    router.push({name: "login"})
               })
           }

           window.addEventListener('resize', () => {
               data.mobileMenuOpen = false
           })

           return {
             logoutApp,
             data,
             openMobileMenu,
             changeMenuItem
           }
        }
    }
</script>


<style scoped>
  @import "homestyle.scss";

  * {
    margin: 0!important;
    padding: 0!important;
  }

  .web-menu
  {
    background-color: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 60px;
    width: 100%;
  }

  .web-menu .title {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 10px;
    margin-left:23px!important;
  }

  .web-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  .web-menu ul li {
    display: block;
    margin-right: 20px!important;
  }

  .web-menu ul li a:link {
      display: block;
      width: 100%;
      padding: 18px!important;
  }

  .web-menu ul li:last-child {
    margin-right: 0;
  }

  .web-menu ul li:hover {
      background: #65204f;
  }

  nav ul li a {
    color: #fff;
    text-decoration: none;
  }

  .logout-btn {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-right: 20px!important;
    background: none;
    color: #fff;
    display: block;
    padding: 10px!important;
  }

  .logout-btn:hover {
      background: #65204f;
  }

  .router-link-active{
      background: #65204f;
  }

  .mobile-menu {
     display: none;
  }

  .mobile-menu-content {
      position: fixed;
      z-index: 1000;
      width: 60vw;
      background: #1e1d1d;
      height: 100vh;
      left: -1000px;
      transition: left .5s;
  }

  .openMobileMenu {
      left: 0
  }

 .close-mobile-menu-button {
     margin-right: 20px!important;
     margin-top: 10px!important;
     background: none;
     border: none;
     color: #fff;
     font-weight: bold;
     font-size: 1.8em;
     text-decoration: none;
 }


  @media (max-width: 768px) {
      .web-menu nav {
         display: none;
      }
      .logout-btn {
         display: none;
      }

      .mobile-menu {
         display: flex;
      }

      .mobile-btn {
          border: none;
          width: 60px;
          height: 60px;
          padding: 10px!important;
          font-size: 1.4em;
          transition: all .5s;
          background: #65204f;
          color: #fff;
      }

    .mobile-btn:hover {
        background: #7b2869;
    }

      .web-menu {
          justify-content: left;
      }
  }
</style>