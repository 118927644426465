<template>
  <div class="container-fluid">
     <div class="row">
         <div class="col-md-2 d-flex justify-content-center" style="padding-top: 30px">
             <upload-preview @upload-image="getUploadedImage"></upload-preview>
         </div>
         <div class="col-md-10">
              <div class="row row-spacing">
                  <div class="col-md-6">
                      <label>Titulo</label>
                      <input v-model="data.book.title" type="text" class="form-control">
                  </div>
                  <div class="col-md-6">
                    <label>Autor</label>
                    <input v-model="data.book.author" type="text" class="form-control">
                  </div>
              </div>

             <div class="row row-spacing">
               <div class="col-md-2">
                 <label>Total Paginas</label>
                 <input v-model="data.book.pages" type="number" class="form-control">
               </div>
               <div class="col-md-5">
                 <label>Editora</label>
                 <input v-model="data.book.publish" type="text" class="form-control">
               </div>
               <div class="col-md-5">
                 <label>Data de publicação</label>
                 <input v-model="data.book.publishDate" type="date" class="form-control">
               </div>
             </div>

           <div class="row row-spacing">
             <div class="col-md-6">
               <label>Categoria</label>
               <VueMultiselectEsm
                   v-model="data.book.categories"
                   :options="data.bookCategories"
                   :multiple="true"
                   :close-on-select="true"
                   placeholder="Selecione uma ou mais categorias"
                   label="description"
                   track-by="description"
               ></VueMultiselectEsm>
             </div>
             <div class="col-md-6">
               <label>Versão</label>
               <VueMultiselectEsm
                   v-model="data.book.versions"
                   :options="data.bookVersions"
                   :multiple="true"
                   :close-on-select="true"
                   placeholder="Selecione uma ou mais versões"
                   label="description"
                   track-by="description"
               ></VueMultiselectEsm>
             </div>
           </div>

           <div class="row row-spacing">
             <div class="col-md-3">
               <label>Código ISBN</label>
               <input type="text" v-model="data.book.isbnCode" class="form-control">
             </div>
             <div class="col-md-3">
               <label>Nacionalidade</label>
               <VueMultiselectEsm
                   v-model="data.book.nationality"
                   :options="data.bookNationality"
                   :multiple="false"
                   :close-on-select="true"
                   placeholder="Selecione uma nacionalidade"
                   label="description"
                   track-by="description"
               ></VueMultiselectEsm>
             </div>
             <div class="col-md-2">
               <label>Ano de leitura</label>
               <input v-model="data.book.readYear" type="number" max="4" class="form-control">
             </div>
             <div class="col-md-4">
               <label>Valor pago</label>
               <CurrencyInput :options="data.moneyInputConfig" v-model="data.book.amount" class="form-control"></CurrencyInput>
             </div>
           </div>

         </div>
     </div>
    <div class="app-modal-footer d-flex justify-content-end">
      <button type="button" @click="cancelForm" style="margin-right: 10px;" class="btn btn-secondary">Cancelar</button>
      <button type="button" @click="sendFormData" class="btn btn-primary app-button">Salvar</button>
    </div>
  </div>
</template>

<script>
    import UploadPreview from "@/components/upload-preview.vue";
    import {onMounted, reactive} from "vue";
    import VueMultiselectEsm from "vue-multiselect";
    import {listAll} from "@/services/api/categoriesService";
    import {listAllVersions} from "@/services/api/versionsService";
    import {alertError} from "@/helper/alertHelper";
    import {translate} from "@/lang/PT_lang";
    import CurrencyInput from "@/components/CurrencyInput.vue";
    import {getMoneyValue} from "@/helper/moneyHelper";
    export default {
       name:"book-fragment",
       components: {CurrencyInput, UploadPreview, VueMultiselectEsm},
       setup(props, ctx) {
          let formData = new FormData()
          let data = reactive({
              bookCategories:[],
              bookVersions:[],
              bookNationality:[
                {description:"Nacional", id:"nacional"},
                {description:"Estrangeiro", id:"estrangeiro"},
              ],
            moneyInputConfig: {
              currency:'BRL',
              locale:'pt-BR',
              precision: 2,
              autoDecimalDigits: true
            },
              book:{
                  title: "",
                  author: "",
                  pages: "",
                  publish:"",
                  publishDate:"",
                  isbnCode: "",
                  readYear: "",
                  amount: "",
                  categories:[],
                  versions: [],
                  nationality: ""
              }
          })
         const getUploadedImage = (image) => {
             formData.set("cover", image)
         }

         const cancelForm = () => {
              ctx.emit("cancel-form")
         }

         const sendFormData = () => {
            let cover = formData.get("cover");
            if(cover === null || cover === undefined) {
                alertError("Atenção", "Seleciona uma capa para o livro")
                return;
            }

            let properties = Object.getOwnPropertyNames(data.book)
            for(let i = 0; i < properties.length; i++) {
               let field = properties[i]
               if(field === "isbnCode"){
                   continue;
               }
               //PARA ARRAYS
               if(Array.isArray(data.book[field])) {
                  if(data.book[field].length === 0) {
                     alertError("Atenção", `Preencha o campo ${translate(field)}`)
                     return;
                  }
               }else{
                  if(data.book[field] === "" || data.book[field] == null) {
                    alertError("Atenção", `Preencha o campo ${translate(field)}`)
                    return;
                  }
               }
            }

            formData.set("title", data.book.title)
            formData.set("author", data.book.author)
            formData.set("category", data.book.categories.map(value => value.id).join(","))
            formData.set("version", data.book.versions.map(value => value.id).join(","))
            formData.set("publisher", data.book.publish)
            formData.set("publishe_date", data.book.publishDate)
            if(data.book.isbnCode !== null || data.book.isbnCode !== "") {
              formData.set("isbn_code", data.book.isbnCode)
            }
           formData.set("nationality", data.book.nationality.id)
           formData.set("read_year", data.book.readYear)
           formData.set("amount_pay", getMoneyValue(data.book.amount))
           formData.set("pages", data.book.pages)

           ctx.emit("book-form", formData)
         }

          onMounted(async () => {
              data.bookCategories = await listAll()
              data.bookVersions = await listAllVersions()
          })

          return {
            data,
            getUploadedImage,
            cancelForm,
            sendFormData
          }
       }
    }
</script>

<style scoped>
.row-spacing {
   margin-bottom: 10px;
}
</style>