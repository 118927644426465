<template>
   <div class="container-fluid bg-login">
     <h1 class="login-title"><font-awesome-icon icon="fa-solid fa-book"></font-awesome-icon> Livraria da Steffani</h1>
        <div class="card login-box">
            <div class="card-body">
                 <h4 class="welcome-title">Bem vindo, Faça seu login</h4>
                <form class="form-login">
                    <login-input type="text" v-model="data.login" label="Login" class="login-input" icon="fa-user"></login-input>
                    <login-input type="password" v-model="data.password" label="Senha" class="login-input" icon="fa-key"></login-input>
                    <button type="button" @click="validateAndLogin" class="login-btn">Entrar</button>
                </form>
            </div>
        </div>
     <loading v-if="data.showLoading" message="Processando aguarde.."></loading>
   </div>
</template>

<script>
   import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
   import {onMounted, reactive} from "vue";
   import {alertError} from "@/helper/alertHelper";
   import store from "@/store";
   import router from "@/router";
   import {authenticate} from "@/services/api/authService";
   import Loading from "@/components/loading/loading.vue";
   import LoginInput from "@/components/login-input.vue";

   export default {
       name: "login",
     components: {LoginInput, Loading, FontAwesomeIcon},
       setup() {

         let data = reactive({
             login: "",
             password: "",
             showLoading: false,
         })

         const validateAndLogin = () => {
             if(data.login === "") {
                 alertError("Atenção", "Preencha o campo usuário")
                 return;
             }

             if(data.password === "") {
               alertError("Atenção", "Preencha o campo usuário")
               return;
             }

           data.showLoading = true

           authenticate(data).then(response =>{
             data.showLoading = false;
             store.commit("enableAuth")
             store.commit("setUserData", response.data)
             router.push({name: "home"})
           }).catch(error => {
             try {
               data.showLoading = false;
               alertError("Atencão", error.response.data.message)
             }catch (ex) {
               data.showLoading = false;
               alertError("Atencão", ex.message)
             }
           })
         }

         onMounted(() => {
             if(store.getters.isAuth) {
               router.push({name: "home"})
             }
         })

         return {
             data,
             validateAndLogin
         }
       }
   }
</script>

<style scoped>
  @import "loginstyle.scss";
</style>