const nationalities = {
    nacional: "Nacional",
    estrangeiro: "Estrangeiro",
}

const getNationality = (value) => {
    return nationalities[value]
}

export {
    getNationality
}