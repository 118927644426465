import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'
import "./assets/general.scss"
import "vue-multiselect/dist/vue-multiselect.esm.css"
/* import font awesome icon component */
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faChartColumn,
    faFilter,
    faHome,
    faPenToSquare,
    faPlusCircle,
    faReceipt,
    faRightFromBracket,
    faSackDollar,
    faSearch,
    faTrash,
    faArrowLeft,
    faArrowRight,
    faCreditCard,
    faChartLine,
    faPiggyBank,
    faMoneyBill,
    faArrowUpRightFromSquare,
    faCircleCheck,
    faBars,
    faEdit,
    faClock,
    faList,
    faHeart,
    faGear,
    faXmark,
    faBoxArchive,
    faBoxOpen,
    faBell,
    faUser,
    faKey,
    faDoorOpen,
    faBook,
    faDashboard,
    faPlus,
    faStar, faEye
} from '@fortawesome/free-solid-svg-icons'

library.add(
    faHome,
    faReceipt,
    faSackDollar,
    faRightFromBracket,
    faChartColumn,
    faPlusCircle,
    faFilter,
    faPenToSquare,
    faTrash,
    faSearch,
    faArrowLeft,
    faArrowRight,
    faCreditCard,
    faChartLine,
    faPiggyBank,
    faMoneyBill,
    faArrowUpRightFromSquare,
    faCircleCheck,
    faBars,
    faPenToSquare,
    faClock,
    faList,
    faHeart,
    faGear,
    faXmark,
    faBoxArchive,
    faBoxOpen,
    faBell,
    faUser,
    faKey,
    faDoorOpen,
    faBook,
    faDashboard,
    faFilter,
    faPlus,
    faStar,
    faEye
)

createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
