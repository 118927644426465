let fields = [
    {name: "title", trad: "Titulo"},
    {name: "author", trad: "Autor"},
    {name: 'pages', trad: ' Total Paginas'},
    {name: 'publish', trad: 'Editora'},
    {name: 'publishDate', trad: 'Data de publicação'},
    {name: 'isbnCode', trad: 'Código ISBN'},
    {name: 'readYear', trad: 'Ano de Leitura'},
    {name: 'amount', trad: 'Valor pago'},
    {name: 'categories', trad: 'Categoria'},
    {name: 'versions', trad: 'Versão'},
    {name: 'nationality', trad: 'Nacionalidade'}
]

const translate = (field) => {
    let filter = fields.filter(f => f.name === field);
    if(filter.length === 1) {
        return filter[0].trad
    }
    return ""
}

export {
    translate
}