<template>
  <div class="input-container">
    <input :type="$props.type" :value="$props.modelValue" @focus="focusInput" @blur="blurInput" @input="updateValue" :class="{'active': $props.modelValue !== ''}" id="input-field" class="input-field">
    <label ref="label" for="input-field" class="input-label"><font-awesome-icon :icon="'fa-solid '+$props.icon"></font-awesome-icon> {{$props.label}}</label>
  </div>
</template>

<script>
  import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

  export default {
    name:"login-input",
    components: {FontAwesomeIcon},
    props:['modelValue', "label", "type", "icon"],
    methods:{
       focusInput() {
           this.$refs.label.classList.add("active")
       },
       blurInput() {
          if(this.modelValue === '') {
             this.$refs.label.classList.remove("active")
          }
       },
       updateValue(event) {
          this.$emit("update:modelValue", event.target.value)
       }
    }
  }
</script>


<style scoped>
.input-container {
  position: relative;
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  font-size: 16px;
  padding: 15px;
  padding-top: 20px;
  padding-left: 10px;
  padding-bottom: 0px;
  transition: all 0.3s ease;
  border: none;
  outline: none;
}

.input-label {
  position: absolute;
  top: 40%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 16px;
  color: #8e3472;
  transition: all 0.3s ease;
}

.input-field:focus + .input-label,
.input-field:not(:placeholder-shown) + .input-label.active {
  font-size: 12px;
  top: 0;
  transform: translateY(0);
  color: #65204f;
}
</style>