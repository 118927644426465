<template>
  <div class="container-fluid">

    <ul class="nav nav-tabs" id="myTabs" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="tab1-tab" data-bs-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">Cadastro</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="tab2-tab" data-bs-toggle="tab" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">Leitura</a>
      </li>

      <li class="nav-item" role="presentation">
        <a class="nav-link" id="tab3-tab" data-bs-toggle="tab" href="#tab3" role="tab" aria-controls="tab2" aria-selected="false">Historico de leitura</a>
      </li>

    </ul>

    <div class="tab-content" id="myTabsContent">
        <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
          <div class="row">
            <div class="col-md-2 d-flex justify-content-center" style="padding-top: 30px">
              <upload-preview  :image-cover="data.book.cover"  @upload-image="getUploadedImage"></upload-preview>
            </div>
            <div class="col-md-10">
              <div class="row row-spacing">
                <div class="col-md-6">
                  <label>Titulo</label>
                  <input  v-model="data.book.title" type="text" class="form-control">
                </div>
                <div class="col-md-6">
                  <label>Autor</label>
                  <input v-model="data.book.author" type="text" class="form-control">
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-2">
                  <label>Total Paginas</label>
                  <input v-model="data.book.pages" type="number" class="form-control">
                </div>
                <div class="col-md-5">
                  <label>Editora</label>
                  <input v-model="data.book.publish" type="text" class="form-control">
                </div>
                <div class="col-md-5">
                  <label>Data de publicação</label>
                  <input v-model="data.book.publishDate" type="date" class="form-control">
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-6">
                  <label>Categoria</label>
                  <VueMultiselectEsm
                      v-model="data.book.categories"
                      :options="data.bookCategories"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Selecione uma ou mais categorias"
                      label="description"
                      track-by="description"
                  ></VueMultiselectEsm>
                </div>
                <div class="col-md-6">
                  <label>Versão</label>
                  <VueMultiselectEsm
                      v-model="data.book.versions"
                      :options="data.bookVersions"
                      :multiple="true"
                      :close-on-select="true"
                      placeholder="Selecione uma ou mais versões"
                      label="description"
                      track-by="description"
                  ></VueMultiselectEsm>
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-3">
                  <label>Código ISBN</label>
                  <input type="text" v-model="data.book.isbnCode" class="form-control">
                </div>
                <div class="col-md-3">
                  <label>Nacionalidade</label>
                  <VueMultiselectEsm
                      v-model="data.book.nationality"
                      :options="data.bookNationality"
                      :multiple="false"
                      :close-on-select="true"
                      placeholder="Selecione uma nacionalidade"
                      label="description"
                      track-by="description"
                  ></VueMultiselectEsm>
                </div>
                <div class="col-md-2">
                  <label>Ano de leitura</label>
                  <input v-model="data.book.readYear" type="number" max="4" class="form-control">
                </div>
                <div class="col-md-4">
                  <label>Valor pago</label>
                  <CurrencyInput :options="data.moneyInputConfig" v-model="data.book.amount" class="form-control"></CurrencyInput>
                </div>
              </div>

              <div class="row row-spacing">
                <div class="col-md-3">
                  <label>Comecei a ler em </label>
                  <input type="date" v-model="data.book.startRead" class="form-control">
                </div>
                <div class="col-md-3">
                  <label>Terminei de ler em </label>
                  <input type="date" class="form-control" v-model="data.book.endRead">
                </div>

                <div class="col-md-3">
                  <label>Avaliação</label>
                  <select class="form-select" v-model="data.book.stars">
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                  </select>
                </div>

                <div class="col-md-3">
                  <label>Favorito</label>
                  <select class="form-select" v-model="data.book.favorite">
                      <option value="false">Não</option>
                      <option value="true">Sim</option>
                  </select>
                </div>

              </div>

            </div>
          </div>
          <div class="app-modal-footer d-flex justify-content-end">
            <button type="button" @click="deleteBook" style="margin-right: 10px;" class="btn btn-danger">Deletar</button>
            <button type="button" @click="cancelForm" style="margin-right: 10px;" class="btn btn-secondary">Cancelar</button>
            <button type="button" @click="sendFormData" class="btn btn-primary app-button">Salvar</button>
          </div>
        </div>

      <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
         <div class="alert alert-success" v-if="data.showFlashAlert" style="margin-top: 15px">
             <h5>Status atualizado com sucesso!!</h5>
         </div>
          <div class="row" style="margin-top: 10px">
              <div class="col-md-3 row-spacing">
                  <label>Status</label>
                  <select @change="changeReadStatus" v-model="data.managerBook.readStatus" class="form-select">
                       <option :value="item.id" v-for="item in data.readStatus">{{item.description}}</option>
                  </select>
              </div>
          </div>

          <div class="row">
              <div class="col-md-12" >
                <div class="add-page d-flex justify-content-end">
                   <div class="btn-add-page-container"  style="position: relative">
                        <button type="button" class="btn btn-secondary" @click="openAddPageForm"><font-awesome-icon icon="fa-solid fa-plus"></font-awesome-icon></button>
                        <div class="form-content" :class="{'form-content-hidden': !data.openPageForm}">
                             <input type="number" placeholder="Paginas ou porcentagem" class="form-control" style="margin-bottom: 10px" v-model="data.managerBook.updateRead.amount">
                             <select class="form-select" style="margin-bottom: 10px" v-model="data.managerBook.updateRead.unit">
                                 <option value="page">Pagina</option>
                                 <option value="percentage">Porcentagem</option>
                             </select>
                             <button type="button" @click="saveUpdatePage" class="btn btn-primary">Adicionar</button>
                        </div>
                   </div>
                </div>
                 <div class="table-content" style="height: auto; max-height: 300px;overflow: auto; margin-bottom: 20px">
                   <no-content message="Ainda não foi iniciada a  leitura" v-if="data.book.start_read_history !== true"></no-content>
                  <table class="table table-striped" v-else>
                    <thead>
                      <tr>
                          <th>Data</th>
                          <th>Pagina/Porcentagem</th>
                          <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in data.currentReadUpdates">
                          <td>{{formatDate(item.date)}}</td>
                          <td>{{item.page}}{{ item.unit === "percentage" ? "%" : ""}}</td>
                          <td><a href="" @click.prevent="deleteUpdatePage(item.id)"><font-awesome-icon icon="fa-solid fa-trash"></font-awesome-icon></a> </td>
                        </tr>
                    </tbody>
                  </table>
                 </div>
              </div>
          </div>
      </div>

      <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
            <div class="col-md-12" >
              <div class="table-content" style="height: auto; max-height: 300px;overflow: auto; margin-bottom: 20px">
                <table class="table table-striped">
                  <thead>
                  <tr>
                    <th>Comecei a ler em</th>
                    <th>Terminei de ler em:</th>
                    <th>Status</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="item in data.book.readHistories">
                    <td>{{formatDate(item.start)}}</td>
                    <td>{{formatDate(item.end)}}</td>
                    <td>{{item.close === 0 ? "Em andamento" : "Finalizada"}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
      </div>

    </div>
  <loading message="Processando aguarde..." v-if="data.showLoading"></loading>
  </div>
</template>

<script>
    import UploadPreview from "@/components/upload-preview.vue";
    import {onMounted, reactive, watch} from "vue";
    import VueMultiselectEsm from "vue-multiselect";
    import {listAllSync} from "@/services/api/categoriesService";
    import {listAllVersionsSync} from "@/services/api/versionsService";
    import {alertConfirm, alertError} from "@/helper/alertHelper";
    import {translate} from "@/lang/PT_lang";
    import CurrencyInput from "@/components/CurrencyInput.vue";
    import {getBookById, getBookByUserAndId} from "@/services/api/bookService";
    import {getMoneyValue} from "@/helper/moneyHelper";
    import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
    import Loading from "@/components/loading/loading.vue";
    import {changeStatus, deleteUpdateReadBook, saveUpdateReadBook} from "@/services/api/managerBookService";
    import NoContent from "@/components/nocontent/NoContent.vue";
    import {formatDate} from "../../../helper/date";
    export default {
       name:"book-manager-fragment",
       methods: {formatDate},
       components: {NoContent, Loading, FontAwesomeIcon, CurrencyInput, UploadPreview, VueMultiselectEsm},
       props:["bookId"],
       setup(props, ctx) {
           let formData = new FormData()

           let data = reactive({
              showLoading: false,
              showFlashAlert: false,
              statusOldValue: "",
              bookCategories:[],
              bookVersions:[],
              bookNationality:[
                {description:"Nacional", id:"nacional"},
                {description:"Estrangeiro", id:"estrangeiro"},
              ],
             readStatus:[
               {description: "Para ler", id:"para_ler"},
               {description: "Lendo", id:"lendo"},
               {description: "Já li", id:"ja_li"},
               {description: "Pausei", id:"pausei"},
               {description: "Abandonei", id:"abandonei"}
             ],
            moneyInputConfig: {
              currency:'BRL',
              locale:'pt-BR',
              precision: 2,
              autoDecimalDigits: true
            },
             book: {
               title: "",
               author: "",
               pages: "",
               publish:"",
               publishDate:"",
               isbnCode: "",
               readYear: "",
               amount: "",
               categories:[],
               versions: [],
               nationality: "",
               stars: "",
               favorite: false,
               startRead: "",
               endRead: "",
             },
             managerBook: {
                readStatus: "",
                startRead: "",
                finishRead: "",
                updateRead: {
                   amount: "",
                   unit:"page"
                }
             },
             currentReadUpdates: [],
             openPageForm: false
          })
         const getUploadedImage = (image) => {
             formData.set("cover", image)
         }

         const cancelForm = () => {
              ctx.emit("cancel-form")
         }

         const deleteBook = () => {
           ctx.emit("delete-book", {title: data.book.title, id: props.bookId})
         }

         const openAddPageForm = () => {
             if(data.book.start_read_history === false) {
                alertError("Atenção", "Inicie uma leitura para incluir o seu progresso")
               return
             }
              if(data.openPageForm) {
                 data.openPageForm = false
                 return
              }
              data.openPageForm = true
         }

         const sendFormData = () => {
            let cover = formData.get("cover");
            if(cover === null || cover === undefined) {
                formData.delete("cover")
            }

            let properties = Object.getOwnPropertyNames(data.book)
                .filter(value => value !== "isbnCode")
                .filter(value => value !== "stars")
                .filter(value => value !== "favorite")
                .filter(value => value !== "startRead")
                .filter(value => value !== "endRead")
                .filter(value => value !== "start_read_history")
                .filter(value => value !== "readHistories")

            for(let i = 0; i < properties.length; i++) {
               let field = properties[i]
               //PARA ARRAYS
               if(Array.isArray(data.book[field])) {
                  if(data.book[field].length === 0) {
                     alertError("Atenção", `Preencha o campo ${translate(field)}`)
                     return;
                  }
               }else{
                  if(data.book[field] === "" || data.book[field] == null) {
                    alertError("Atenção", `Preencha o campo ${translate(field)}`)
                    return;
                  }
               }
            }

            formData.set("title", data.book.title)
            formData.set("author", data.book.author)
            formData.set("category", data.book.categories.map(value => value.id).join(","))
            formData.set("version", data.book.versions.map(value => value.id).join(","))
            formData.set("publisher", data.book.publish)
            formData.set("publishe_date", data.book.publishDate)
            if(data.book.isbnCode !== null || data.book.isbnCode !== "") {
              formData.set("isbn_code", data.book.isbnCode)
            }
           formData.set("nationality", data.book.nationality.id)
           formData.set("read_year", data.book.readYear)
           formData.set("amount_pay", getMoneyValue(data.book.amount))
           formData.set("pages", data.book.pages)
           formData.set("favorite", data.book.favorite)
           formData.set("stars", data.book.stars === ""? 0:data.book.stars)

           if(data.book.startRead !== "") {
             formData.set("start_read", data.book.startRead)
           }

           if(data.book.endRead !== "") {
             formData.set("end_read", data.book.endRead)
           }

           ctx.emit("book-form-update", {book:formData, bookId: props.bookId})
         }

         const changeReadStatus = () => {
             if((data.managerBook.readStatus === "lendo" || data.managerBook.readStatus === "relendo") && data.book.start_read_history === false) {
                 alertConfirm("Confirmação", "Deseja iniciar uma nova leitura ?", () => {
                     changeStatus(data, props.bookId)
                 }, () => {
                      data.managerBook.readStatus = data.statusOldValue
                 })
               return;
             }

             if((data.managerBook.readStatus === "ja_li" || data.managerBook.readStatus === "abandonei") && data.book.start_read_history === true) {
               alertConfirm("Confirmação", "Deseja finalizar a leitura ?", () => {
                 changeStatus(data, props.bookId)
               }, () => {
                   data.managerBook.readStatus = data.statusOldValue
               })
               return;
             }
             changeStatus(data, props.bookId)
         }

         watch(() => data.managerBook.readStatus, (newValue, oldValue) => {
              data.statusOldValue = oldValue
         })

         const saveUpdatePage = () => {

           if(data.managerBook.updateRead.amount === "") {
             alertError("Atenção", "Preencha o total de paginas ou porcentagem de leitura")
             return
           }

            if((data.managerBook.updateRead.amount > data.book.pages) && data.managerBook.updateRead.unit === "page") {
              alertError("Atenção", "O valor não pode ser maior que o numero de paginas do livro")
              return
            }

           if((data.managerBook.updateRead.amount > 100) && data.managerBook.updateRead.unit === "percentage") {
             alertError("Atenção", "O valor não pode ser maior que 100%")
             return
           }

           saveUpdateReadBook(data, props.bookId)
         }

         const deleteUpdatePage = (readUpdateId) => {
              deleteUpdateReadBook(data, props.bookId, readUpdateId)
         }

          onMounted(() => {
            listAllSync(data)
            listAllVersionsSync(data)
            getBookByUserAndId(data, props.bookId)
          })

          return {
            data,
            getUploadedImage,
            cancelForm,
            sendFormData,
            deleteBook,
            openAddPageForm,
            changeReadStatus,
            saveUpdatePage,
            deleteUpdatePage
          }
       }
    }
</script>

<style scoped>
.row-spacing {
   margin-bottom: 10px;
}

.form-content {
  position: absolute;
  background: #ece9e9;
  border-radius: 10px;
  padding: 10px;
  top: -30px;
  right: 40px;
  width: 200px;
}

.form-content-hidden {
   display: none;
}

</style>