import Store from "@/store";
import httpService from "@/services/http/HttpService";
import {alertError} from "@/helper/alertHelper";

const getLastReads = (data) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/book-reads/last`

     httpService.get(url).then(result => {
          data.lastReadBooks = result.data
     }).catch(e => {
          alertError("Atenção", "Falha ao obter lista de ultimos livros lidos")
     });
}

export {
    getLastReads
}