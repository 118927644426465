<template>
   <div class="container-fluid dashboard-content" >
<!--       <div class="row">-->
<!--           <div class="col-md-12">-->
<!--               <div class="card">-->
<!--                   <div class="card-title card-title-sub">Paginômetro</div>-->
<!--                   <div class="card-body">-->
<!--                       <p class="dashboard-counter">3000</p>-->
<!--                   </div>-->
<!--               </div>-->
<!--           </div>-->
<!--       </div>-->

      <div class="row row-spacing">
        <div class="col-md-6" v-for="item in data.dashboard.readCounts" style="margin-bottom: 20px">
            <div class="card">
              <div class="card-title card-title-sub">{{formatStatus(item.description)}}</div>
              <div class="card-body">
                <p class="dashboard-counter">{{item.count}}</p>
              </div>
            </div>
        </div>
      </div>

      <div class="row row-spacing">
          <div class="col-md-12">
             <div class="card">
                 <div class="card-title card-title-sub">
                        Por categoria
                 </div>
                 <div class="card-body">
                      <ul class="counter-list">
                           <li v-for="item in data.dashboard.readCountsPerGender">
                               <h5>{{item.description}}</h5>
                               <span>{{item.count}}</span>
                           </li>
                      </ul>
                 </div>
             </div>
          </div>
      </div>

   </div>
</template>

<script>
import {onMounted, reactive} from "vue";
import {getReadCountersDashBoard} from "@/services/api/dashboardService";
import {formatStatus} from "../../utils/readStatusFormatUtils";

    export default {
      name:"dashboard",
      methods: {formatStatus},
      setup(){
          let data = reactive({
              dashboard:{
                  readCounts:[],
                  readCountsPerGender:[],
              }
          })

         onMounted(() => {
            getReadCountersDashBoard(data)
         })

         return {
            data
         }
      }
    }
</script>


<style scoped>
   .dashboard-content {
       padding: 20px!important;
   }

   .card-title-sub{
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
        margin: 0;
        padding-top: 20px;
   }

   .dashboard-counter {
       font-weight: bold;
       width: 100%;
       font-size: 3em;
       text-align: center;
       margin-top: -30px;
       padding: 0;
   }

   .row-spacing {
       margin-top: 20px;
   }

   .counter-list {
     display: grid;
     grid-template-columns: repeat(5, 1fr);
     list-style: none;
     padding: 0;
     text-align: center;
     flex-wrap: wrap;
   }

   .counter-list li {
      margin-right: 40px;
      text-align: center;
      background: #f5f4f4;
      padding: 10px;
      border-radius: 10px;
     margin-bottom: 20px;
   }

   .counter-list li span {
       font-weight: bold;
       font-size: 3em;
   }

   .counter-list li h5 {
      font-size: 1.6em;
   }

   @media (max-width: 767.98px) {
     .counter-list {
       grid-template-columns: repeat(2, 1fr);
     }

     .counter-list li {
       margin-bottom: 15px;
       width: calc(100% - 10px);
       margin-right: 10px;
     }
   }

</style>