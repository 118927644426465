import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Loginview from "@/views/login/loginview.vue";
import Home from "@/views/home/Home.vue";
import Mybookview from "@/views/mybooks/mybookview.vue";
import store from "@/store";
import Dashboard from "@/views/dashboard/dashboard.vue";
import BookCreateForm from "@/views/mybooks/book-create-form.vue";
import BookManagerForm from "@/views/mybooks/book-manager-form.vue";

const routes = [
  {
    path: '/',
    name: 'login',
    component: Loginview
  },
  {
    path: '/app',
    name:'home',
    component: Home,
    redirect: "/app/my-books",
    children: [
      {
        path: '/app/dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
    {
        path: '/app/my-books',
        name: 'my-books',
        component: Mybookview,
      },

      {
        path: '/app/my-books/create-book',
        name: 'create-book-form',
        component: BookCreateForm,
      },

      {
        path: '/app/my-books/:bookId/manager',
        name: 'book-manager-form',
        component: BookManagerForm,
      },

    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  if(to.name !== 'login') {
    let user = store.getters.userData;

    if(!store.getters.isAuth) {
      return router.push({name: "login"})
    }

    if(user.token === "" || !user.hasOwnProperty("token")) {
      return router.push({name: "login"})
    }
  }
});

export default router
