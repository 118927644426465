import {alertError} from "@/helper/alertHelper";
import httpService from "@/services/http/HttpService";

const listAllVersions = async () => {
     try {
        let result = await httpService.get("/book-versions");
        return result.data;
     }catch (e) {
         alertError("Atenção", "Falha ao buscar versões de livro")
     }
}

const listAllVersionsSync = (data) => {
    httpService.get("/book-versions").then(result => {
         data.bookVersions = result.data
    }).catch(error => {
        alertError("Atenção", "Falha ao buscar versões de livro")
    })
}

export {
    listAllVersions,
    listAllVersionsSync
}