import Store from "@/store";
import httpService from "@/services/http/HttpService";
import {alertError} from "@/helper/alertHelper";

const getReadCountersDashBoard = (data) => {
    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/dashboard`

    httpService.get(url).then(result => {
        data.dashboard.readCounts = result.data.read_counts
        data.dashboard.readCountsPerGender = result.data.read_counts_per_gender
    }).catch(e => {
        alertError("Atenção", "Falha ao buscar dados para o dashboard")
    })
}

export {
    getReadCountersDashBoard
}