import {alertError} from "@/helper/alertHelper";
import Store from "@/store";
import httpService from "@/services/http/HttpService";
import {filter} from "core-js/internals/array-iteration";

const getFilters = (data) => {

    let userId = Store.getters.userData.userId
    let url = `/users/${userId}/book-filters`

    httpService.get(url).then(result => {
         data.filters = result.data.map(filter  => {
             let items = filter.items.map(item => {
                  item.active = false
                  return item
             })
             filter.items = items
             return filter
         })
    }).catch(error => {
        alertError("Atenção", "Falha ao obter filtros")
    })
}
export {
    getFilters
}