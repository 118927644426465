<template>
  <div class="card sidebar-card">
    <div class="card-body">
      <h3 class="filter-title"><font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon> Minha estante</h3>
      <a href="" @click.prevent="clearFilters" class="clear-filter" :class="{'item_book_active':dataReactive.allCategories}">Todos os livros</a>
      <div class="accordion accordion-flush" id="filters">
        <div class="accordion-item" v-for="(filter, index) in $props.data" :id="`accordion_${index}`">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#${filter.name}`" aria-expanded="true" :aria-controls="filter.name">
              {{filter.name}}
            </button>
          </h2>
          <div :id="`${filter.name}`" class="accordion-collapse collapse" aria-labelledby="headingOne">
            <div class="accordion-body">
              <ul style="padding-left: 0">
                <li style="display: flex; justify-content: space-between; width: 100%; margin-bottom: 10px" v-for="(item, index) in filter.items"><span><a href="" :class="{'item_active':index === getIndex(filter.name)}" class="filter-item-link" @click.prevent="emitFilterEvent(filter.name, item , index)">{{formatLabels(filter.name,item.description)}}</a></span><span style="align-self: flex-end" class="badge bg-primary badge-custom">{{item.value}}</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
   import {formatStatus} from "@/utils/readStatusFormatUtils";
   import {reactive} from "vue";
   import filters from "@/utils/FilterConstant";

   export default {
       name: "book-filter",
       props: ['data'],
       setup(props, cx) {

          let dataReactive = reactive({
               nationalitySelectedIndex: -1,
               versionSelectedIndex: -1,
               categorySelectedIndex: -1,
               statusSelectedIndex: -1,
               allCategories: true
          });


         const emitFilterEvent = (filterName, item, index) => {
              dataReactive.allCategories = false

              if(filterName === filters.Nacionalidade) {
                  dataReactive.nationalitySelectedIndex = index
              }

             if(filterName === filters.Versao) {
               dataReactive.versionSelectedIndex = index
             }

             if(filterName === filters.Genero) {
               dataReactive.categorySelectedIndex = index
             }

             if(filterName === filters.Status) {
               dataReactive.statusSelectedIndex = index
             }

             cx.emit("filter-item", {filterName, itemDescription:item.description, itemSlugName: item.slugName})
         }

         const getIndex = (filterName) => {

           if(filterName === filters.Nacionalidade){
               return dataReactive.nationalitySelectedIndex
           }

           if(filterName === filters.Versao) {
              return dataReactive.versionSelectedIndex
           }

           if(filterName === filters.Genero) {
             return dataReactive.categorySelectedIndex
           }

           if(filterName === filters.Status) {
             return dataReactive.statusSelectedIndex
           }
         }


         const clearFilters = () => {
              dataReactive.allCategories = true
              dataReactive.statusSelectedIndex = -1
              dataReactive.categorySelectedIndex = -1
              dataReactive.versionSelectedIndex = -1
              dataReactive.nationalitySelectedIndex = -1
              let accordionsButton = document.querySelectorAll(".accordion-button")
              for(let i = 0; i<accordionsButton.length;i++) {
                  let collapsedButton = accordionsButton[i]
                  if(!collapsedButton.classList.contains("collapsed")) {
                     collapsedButton.click()
                  }
              }
              cx.emit("clear-filters")
         }

         const formatLabels = (filter, description) => {
              if(filter === "Nacionalidade") {
                  if(description === "nacional") {
                      return "Nacional"
                  }

                  if(description === "estrangeiro") {
                    return "Estrangeiro"
                  }
              }

             if(filter === "Status") {
                  return formatStatus(description)
             }

              return description;
         }

         return {
           emitFilterEvent,
           formatLabels,
           getIndex,
           clearFilters,
           dataReactive
         }
       }
   }
</script>

<style scoped>
.filter-title {
  font-size: 1.2em;
}

.filter-itens li {
  padding: 5px;
}

.filter-item-link {
  text-decoration: none;
  color:#8e3472;
}

.badge-custom {
   background: #8e3472!important;
}

.item_active {
   font-weight: bold;
}

.item_book_active {
  background: #8e3472!important;
  color: #fff!important;
}


.accordion-button:not(.collapsed) {
    background: #8e3472!important;
    color:#FFFFFF!important;
}

.card-actions ul {
  list-style: none;
  padding: 0;
}

.clear-filter {
   padding: 15px;
   text-decoration: none;
   border-bottom: solid 1px #dcdcdc;
   width: 100%;
   display: block;
   margin-top: 25px;
   color:#000;
}
</style>
