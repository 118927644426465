import axios from "axios";
import store from "../../store/index"
import {useRouter} from "vue-router";

let axiosOption = {
    baseURL:"https://api-livraria.globalapps.xyz/v1",
}

// let axiosOption = {
//     baseURL:"http://192.168.0.103:8081/v1",
// }

let client = axios.create(axiosOption)

let vueJsRouter = useRouter()


client.interceptors.request.use((config) => {
     if(store.getters.isAuth){
         config.headers.Authorization = ("Bearer ") + (store.getters.userData.token !== undefined ? store.getters.userData.token : "")
         return config
     }
     return config
})

client.interceptors.response.use((response) => {
    return response
}, (error) => {
      if(error.response && error.response.status === 401) {
          console.log("aqui")
          store.commit("clearState")
          window.location = "/"
      }
      return Promise.reject(error)
})

export default client